
import { computed, defineComponent, watch, reactive, ref } from 'vue'
import sectionLayoutContent from '@/components/common/section-layout-content.vue'
import { useResource } from '@/services'
import { useRoute } from 'vue-router'
import { formatTime } from '@/utils/dateTime'
import useOrderRepositories from '@/repositories/useOrderRepositories'
import { ElMessageBox } from 'element-plus'
import { createHandler } from '@/services/createHandler'
import { useNotification } from '@/composables'
import { formatterDollars } from '@/utils/formatter'
import { getOrderStatusColor } from '@/utils/orderUtils'
import { statusTitleMap } from '@/utils/constants'
import { imageUrl } from '@/utils/image'
import OrderImagePreview from '../orders/components/OrderImagePreview.vue'
import Pagination from '@/components/common/Pagination.vue'

export default defineComponent({
  components: {
    sectionLayoutContent,
    OrderImagePreview,
    Pagination,
  },
  setup() {
    const breadcrumbs = [
      {
        text: 'Invoices',
        link: 'seller.invoices',
        params: null,
      },
      {
        text: 'Detail',
        link: '',
        params: null,
      },
    ]
    const title = 'Invoices'
    let loading = ref(false)
    const baseResource = 'invoices'
    const updateDialog = ref()
    const router = useRoute()
    let invoice = ref({
      _id: '',
      seller: '',
      type: '',
      sellerTeam: {},
      agency: '',
      financialStatus: '',
      status: '',
      approvedUser: '',
      createdUser: '',
      remainAmount: 0,
      agencyAmount: 0,
      sellerAmount: 0,
      name: '',
    })
    let members = ref([])
    const fectchRecord = async () => {
      loading.value = true
      const { get } = useResource(baseResource)
      const { data, status } = await get(router.params.id)
      if (status === 200) {
        invoice.value = data as any
        getData()
      }
      loading.value = false
    }
    const content = computed(() => {
      return { breadcrumbs, title }
    })
    fectchRecord()

    const params = reactive({
      page: 1,
      limit: 10,
      invoice: '',
      rejectedInvoiceId: '',
    })
    const columns = [
      {
        key: 'name',
        title: 'Order',
        dataIndex: 'orderName',
        slots: { title: 'customTitle', customRender: 'orderName' },
      },
      {
        title: 'Status',
        dataIndex: 'status',
      },
      {
        title: 'Amount',
        dataIndex: 'sellerAmount',
      },
    ]
    const { getOrders, state } = useOrderRepositories()
    const getData = async () => {
      if (invoice.value?.type === 'OUT') {
        params.invoice = invoice.value?._id
      } else {
        params.rejectedInvoiceId = invoice.value?._id
      }
      await getOrders(params)
    }

    watch(params, getData)

    const onRemove = (invoice: any) => {
      console.log('remove', invoice)
    }
    const { error, success } = useNotification()
    const onReject = (invoice: any) => {
      const resource = `invoices/${invoice._id}/reject`
      ElMessageBox.confirm(`Are you sure to reject this invoices?`)
        .then(async () => {
          const { create, isLoading } = createHandler(resource, {})
          loading = isLoading
          const { status, data } = await create()
          if (status === 201) {
            success('Success')
            getOrders(params)
          } else {
            error(data.message)
          }
        })
        .catch(err => {
          console.log(err)
          error
        })
    }

    const downloadFile = (fileName: string, urlData: any) => {
      var aLink = document.createElement('a')
      aLink.download = fileName
      aLink.href = urlData
      var event = new MouseEvent('click')
      aLink.dispatchEvent(event)
    }

    const { exportOrder } = useOrderRepositories()

    const onExportInvoice = async (row: any) => {
      let res = null
      try {
        loading.value = true
        if (row.type === 'OUT') {
          res = (await exportOrder({
            invoice: row._id,
          })) as any
        } else {
          res = (await exportOrder({
            rejectedInvoiceId: row._id,
          })) as any
        }
        downloadFile(`Export-invoice.csv`, res?.data?.url)
        success(res?.data?.message || `Export success`)
      } catch (e) {
        error()
      }
      loading.value = false
    }

    return {
      loading,
      content,
      updateDialog,
      invoice,
      columns,
      members,
      params,
      state,
      formatTime,
      formatterDollars,
      onRemove,
      onReject,
      getOrderStatusColor,
      statusTitleMap,
      imageUrl,
      onExportInvoice,
    }
  },
})
