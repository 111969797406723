import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "order-item-artwork" }
const _hoisted_2 = { class: "thumbnail-wrapper border" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_slot_error = _resolveComponent("slot-error")!
  const _component_el_image = _resolveComponent("el-image")!
  const _component_UploadArtwork = _resolveComponent("UploadArtwork")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_el_image, {
        src: _ctx.imageUrl(_ctx.item.imageSrc, true, 400),
        class: "image-wrapper",
        title: _ctx.imageUrl(_ctx.item.imageSrc)
      }, {
        error: _withCtx(() => [
          _createVNode(_component_slot_error)
        ]),
        _: 1
      }, 8, ["src", "title"])
    ]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.artworkSpaces, (artwork) => {
      return (_openBlock(), _createElementBlock("div", {
        class: "p-1 order-artwork h-100 border",
        key: artwork
      }, [
        (_ctx.item[artwork])
          ? (_openBlock(), _createBlock(_component_el_image, {
              key: 0,
              src: _ctx.imageUrl(_ctx.item[artwork], true, 300),
              class: "image-wrapper",
              title: _ctx.imageUrl(_ctx.item[artwork])
            }, {
              error: _withCtx(() => [
                _createVNode(_component_slot_error)
              ]),
              _: 2
            }, 1032, ["src", "title"]))
          : (_openBlock(), _createBlock(_component_UploadArtwork, {
              key: 1,
              onUploaded: _ctx.uploaded,
              artworkName: artwork,
              itemIndex: _ctx.index,
              disableEdit: _ctx.disableEditStatus,
              class: "order-artwork-upload"
            }, null, 8, ["onUploaded", "artworkName", "itemIndex", "disableEdit"]))
      ]))
    }), 128))
  ]))
}